import React, { Suspense, useEffect } from 'react';
import { Provider, connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import i18n from 'i18next';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as ThemeProviderOlyDs } from 'styled-components/native';
import * as braze from '@braze/web-sdk';

import { ModalProvider } from 'contexts/Modal';
import ErrorBoundary from 'components/ErrorBoundary';
import BrazeContentCard from 'components/BrazeContentCard';
import { Mixpanel } from 'utils/mixpanel';
import { getUserLanguage } from 'utils/language';
import storage from 'utils/storage';
import Notifications from 'components/Notifications';
import Modals from 'components/Modals';
import { authIsAuthenticatedSelector, authUserSelector } from 'redux/auth/selectors';
import { openModal } from 'redux/modal/actions';
import userClientsSelectors from 'redux/user/clients/selectors';
import userClientsActions from 'redux/user/clients/actions';
import { clientsCurrentIdSelector } from 'redux/clients/selectors';

import theme from './styles/theme';
import themeOlyDs from './styles/themeOlyDs';

const { ZENDESK_WIDGET_KEY, BRAZE_KEY, BRAZE_ENDPOINT } = process.env;

const Auth = React.lazy(() => import(/* webpackChunkName: "views_Auth" */ './views/Auth'));
const Dashboard = React.lazy(() =>
  import(/* webpackChunkName: "views_Dashboard" */ './views/Dashboard'),
);
const Login = React.lazy(() => import(/* webpackChunkName: "views_Login" */ './views/Login'));

const PROVIDERS = ({ store }) => [
  {
    el: ThemeProviderOlyDs,
    props: { theme: themeOlyDs },
  },
  {
    el: ThemeProvider,
    props: {
      theme,
    },
  },
  {
    el: Provider,
    props: {
      store,
    },
  },
];

const CombinedProvider = props => {
  const { providers, children } = props;
  return providers.reduce((acc, v) => {
    const { el: Provider, props = {} } = v;
    return <Provider {...props}>{acc}</Provider>; // eslint-disable-line react/jsx-props-no-spreading
  }, children);
};

const App = props => {
  const {
    store,
    loading,
    isAuthenticated,
    authUser,
    loadOneUserClient,
    clientsCurrentId,
    currentUserClient,
    setCurrentUserClientId,
    openModal,
  } = props;

  useEffect(() => {
    const confirmedCookiesUserId = storage.get(`confirmedCookiesUserId`);

    if (authUser && confirmedCookiesUserId !== authUser?._id) {
      storage.remove('userConfirmedCookies');

      openModal({
        type: 'cookies',
        entity: 'common',
      });
    }
  }, [authUser, isAuthenticated, openModal]);

  useEffect(() => {
    if (isAuthenticated && authUser) {
      const { _id, first_name: firstName, last_name: lastName, email } = authUser;

      Mixpanel.identify(_id);
      Mixpanel.setPeople({
        firstName,
        lastName,
        email,
      });
    }
  }, [authUser, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && authUser && !!clientsCurrentId) {
      loadOneUserClient({ userId: authUser._id, clientId: clientsCurrentId });
      setCurrentUserClientId(clientsCurrentId);
    }
  }, [loadOneUserClient, authUser, clientsCurrentId, isAuthenticated, setCurrentUserClientId]);

  useEffect(() => {
    if (currentUserClient) {
      const userLanguage = getUserLanguage(currentUserClient);
      const storedLanguage = storage.get('language');

      if (!storedLanguage) {
        i18n.changeLanguage(userLanguage);
      }
    }
  }, [currentUserClient]);

  useEffect(() => {
    braze.initialize(BRAZE_KEY, {
      baseUrl: BRAZE_ENDPOINT,
    });

    if (authUser) {
      braze.changeUser(authUser._id);
    }

    braze.automaticallyShowInAppMessages();
    braze.openSession();
  }, [authUser]);

  return (
    <Suspense fallback={null}>
      {!loading ? (
        <CombinedProvider providers={PROVIDERS({ store })}>
          <ModalProvider>
            <Router>
              <ErrorBoundary>
                <Notifications />
                <Modals />

                <Suspense fallback={null}>
                  <Switch>
                    <Route exact path="/login" component={Login} />
                    <Auth component={Dashboard} />
                  </Switch>
                </Suspense>
              </ErrorBoundary>
            </Router>
          </ModalProvider>
        </CombinedProvider>
      ) : null}
      <Zendesk
        defer
        zendeskKey={ZENDESK_WIDGET_KEY}
        theme={{ color: '#000' }}
        position={{ horizontal: 'left', vertical: 'bottom' }}
        offset={{ horizontal: '100px' }}
        onLoaded={() => {
          ZendeskAPI('webWidget', 'hide');
          ZendeskAPI('webWidget:on', 'open', () => {
            ZendeskAPI('webWidget', 'show');
          });
          ZendeskAPI('webWidget:on', 'close', () => {
            ZendeskAPI('webWidget', 'hide');
          });
        }}
      />
    </Suspense>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: authIsAuthenticatedSelector(state),
    authUser: authUserSelector(state),
    clientsCurrentId: clientsCurrentIdSelector(state),
    currentUserClient: userClientsSelectors.getCurrentUserClientSelector(state),
  };
};

const mapDispatchToProps = {
  loadOneUserClient: userClientsActions.loadOneUserClient,
  setCurrentUserClientId: userClientsActions.setCurrentUserClientId,
  openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
